export enum PaymentMethodEnum {
    momo_ewallet = "momo_ewallet",
    zalopay_ewallet = "zalopay_ewallet",
    shopeepay_ewallet = "shopeepay_ewallet",
    vnpay_ewallet = "vnpay_ewallet",
    vnpay_creditcard = "vnpay_creditcard",
    vnpay_token = "vnpay_token",
    vnpay_atm = "vnpay_atm",
    vnpay_qr = "vnpay_qr",
    mocagrab_ewallet = "mocagrab_ewallet",
    vnpay_token_create = "vnpay_token_create",
    vnpay_token_pay = "vnpay_token_pay",
    vnpay_token_pay_and_create = "vnpay_token_pay_and_create",
    vnpay_qr_offline = "vnpay_qr_offline",
    vnpt_dcpay = "vnpt_dcpay",
    alliex_pay = "alliex_pay",
    vnpt_megapay = "vnpt_megapay",
    cnf_bnpl = "cnf_bnpl",
    infoplus_dcpay = "infoplus_dcpay",
}

export const FilterMethodPayment = [
    {
        text: "ATM",
        value: PaymentMethodEnum.vnpay_atm,
    },
    {
        text: "Credit Card",
        value: PaymentMethodEnum.vnpay_creditcard,
    },
    {
        text: "VNPay",
        value: PaymentMethodEnum.vnpay_ewallet,
    },
    {
        text: "VNPay QR",
        value: PaymentMethodEnum.vnpay_qr,
    },
    {
        text: "VNPay Token Create",
        value: PaymentMethodEnum.vnpay_token_create,
    },
    {
        text: "VNPay Token Pay",
        value: PaymentMethodEnum.vnpay_token_pay,
    },
    {
        text: "VNPay Token Pay And Create",
        value: PaymentMethodEnum.vnpay_token_pay_and_create,
    },
    {
        text: "Vnpay QR Offline",
        value: PaymentMethodEnum.vnpay_qr_offline,
    },
    {
        text: "ShopeePay",
        value: PaymentMethodEnum.shopeepay_ewallet,
    },
    {
        text: "Momo E Wallet",
        value: PaymentMethodEnum.momo_ewallet,
    },
    {
        text: "Zalo E Wallet",
        value: PaymentMethodEnum.zalopay_ewallet,
    },
    {
        text: "Vnpt DCPay",
        value: PaymentMethodEnum.vnpt_dcpay,
    },
    {
        text: "Alliex Pay",
        value: PaymentMethodEnum.alliex_pay,
    },
    {
        text: "VNPT Megapay",
        value: PaymentMethodEnum.vnpt_megapay,
    },
    {
        text: "Lotte C&F BNPL",
        value: PaymentMethodEnum.cnf_bnpl,
    },
    {
        text: "Info Plus",
        value: PaymentMethodEnum.infoplus_dcpay,
    },
];

export enum getPaymentMethodEnumApiKey {
    momo_ewallet = "momo_ewallet",
    zalopay_ewallet = "zalopay_ewallet",
    shopeepay_ewallet = "shopeepay_ewallet",
    vnpay_ewallet = "vnpay_ewallet",
    vnpay_creditcard = "vnpay_creditcard",
    vnpay_token = "vnpay_token",
    vnpay_atm = "vnpay_atm",
    vnpay_qr = "vnpay_qr",
    mocagrab_ewallet = "mocagrab_ewallet",
    vnpay_token_create = "vnpay_ewallet",
    vnpay_token_pay = "vnpay_ewallet",
    vnpay_token_pay_and_create = "vnpay_ewallet",
    vnpay_qr_offline = "vnpay_qr_offline",
    vnpt_dcpay = "vnpt_dcpay",
    alliex_pay = "alliex_pay",
    vnpt_megapay = "vnpt_megapay",
    cnf_bnpl = "cnf_bnpl",
    infoplus_dcpay = "infoplus_dcpay",
}

export enum PaymentStatus {
    Processing = "processing",
    Failed = "failed",
    Success = "success",
    Refunded = "refunded",
    Canceled = "canceled",
}
export const FilterPaymentStatus = [
    {
        text: "Success",
        value: PaymentStatus.Success,
    },
    {
        text: "Processing",
        value: PaymentStatus.Processing,
    },
    {
        text: "Refunded",
        value: PaymentStatus.Refunded,
    },
    {
        text: "Failed",
        value: PaymentStatus.Failed,
    },
    {
        text: "Canceled",
        value: PaymentStatus.Canceled,
    },
];

export enum TypeTransaction {
    Refund = "refund",
    FirmBanking = "firm_banking",
}
