import { Layout, Row, Col, Dropdown, MenuProps, Form, Button } from "antd";
import classes from "./style.module.less";
import { Avatar } from "components/avatar";
import { RootState } from "redux/reducers";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "components/select";
import { AuthCookies } from "utils";
import { useNavigate, useSearchParams, redirect  } from "react-router-dom";
import FormItem from "antd/es/form/FormItem";
import { logout } from "@ldcc/auth/src/redux/actions";
import { UserOutlined, LogoutOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons"

interface Props {
    collapsed: boolean,
    setCollapsed(value: boolean): void
}
export const Header: React.FC<Props> = ({collapsed, setCollapsed}) => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { projectsMembership, projectOwner } = useSelector((state: RootState) => state.projectsMembership)
    const { data } = useSelector((state: RootState) => state.profile);

    let projectSelect = [];
    if (projectsMembership) {
        projectSelect = projectsMembership?.map(project => {
            return {
                value: project.project?.id,
                data: project.project?.name
            }
        })
    }
    const handleChange = async (value: string) => {
        navigate({ search: `?projectId=${value}` });
        window.location.reload();
    };

    React.useEffect(() => {
        if (!searchParams.get('projectId') && projectOwner?.id) {
            navigate(`?projectId=${projectOwner?.id}`)
        }
        if (!AuthCookies.loggedIn) {
            navigate('/login')
        }
        form.setFieldsValue({
            project: searchParams.get('projectId')
        });

    }, [form, navigate, projectOwner, searchParams])

    const items: MenuProps["items"] = [
        {
            label: <span><UserOutlined /> Profile</span>,
            key: "1",
            onClick: () => {
                navigate(`/profile?projectId=${searchParams.get('projectId')}`)
            },
        },
        {
            label: <span><LogoutOutlined /> Logout</span>,
            key: "2",
            onClick: () => {
                dispatch(logout(AuthCookies.refreshToken));
            },
        }
    ];

    return (
        <>
            <div className={classes.headerWrapper}>
                <Layout.Header className={classes.header} style={classes}>
                    <Row
                        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                        justify="center"
                        align="bottom"
                    >
                        <Col className="gutter-row" span={4} >
                            <Button
                                type="text"
                                icon={collapsed ? <MenuUnfoldOutlined style={{ fontSize: '25px'}}/> : <MenuFoldOutlined style={{ fontSize: '25px'}}/>}
                                onClick={() => setCollapsed(!collapsed)}
                                style={{
                                    position: 'absolute',
                                    top: '-35px',
                                    left: '-10px',
                                }}
                            />
                            <Form>
                                {/* <FormItem >
                                    <Select label="Customer name" data={datas}></Select>
                                </FormItem> */}
                            </Form>
                        </Col>
                        <Col className="gutter-row" span={4}>
                            <Form form={form}>
                                <FormItem name='project'>
                                    <Select label="Project name" data={projectSelect} defaultActiveFirstOption={false} onChange={handleChange}></Select>
                                </FormItem>
                            </Form>
                        </Col>
                        <Col className="gutter-row" span={10}>
                            <div className={classes.search}>
                                {/* <Input
                                    size="large"
                                    placeholder="large size"
                                    prefix={<CaretDownOutlined />}
                                /> */}
                            </div>
                        </Col>
                        <Col className="gutter-row" span={6}>
                            <div className={classes.headerRight}>
                                <Dropdown menu={{ items }} trigger={["click"]}>
                                    <div className={classes.name}>
                                        <Avatar
                                            size={46}
                                            src={data?.profilePictureUrl}
                                            fullName={data?.name}
                                            className={""}
                                            style={undefined}
                                            vip={false} // vip={"paid"}
                                        />
                                        <div className={classes.fullName}>
                                            <strong>{data?.name}</strong>
                                        </div>
                                    </div>
                                </Dropdown>
                            </div>
                        </Col>
                    </Row>
                </Layout.Header>
            </div>
        </>
    );
};
