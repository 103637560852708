import { TransFormFromStringToArrayByCommaSymbol } from "@ldcc/client/src/utils";
import { PaymentMethodEnum, PaymentStatus } from "./constant";
import { IAlertConfig } from "../redux/interface";
export const getMethodPayment = {
    [PaymentMethodEnum.momo_ewallet]: [PaymentMethodEnum.momo_ewallet],
    [PaymentMethodEnum.zalopay_ewallet]: [PaymentMethodEnum.zalopay_ewallet],
    [PaymentMethodEnum.shopeepay_ewallet]: [
        PaymentMethodEnum.shopeepay_ewallet,
    ],
    [PaymentMethodEnum.mocagrab_ewallet]: [PaymentMethodEnum.mocagrab_ewallet],
    [PaymentMethodEnum.vnpay_ewallet]: [PaymentMethodEnum.vnpay_ewallet],
    [PaymentMethodEnum.vnpay_creditcard]: [PaymentMethodEnum.vnpay_ewallet],
    [PaymentMethodEnum.vnpay_atm]: [PaymentMethodEnum.vnpay_ewallet],
    [PaymentMethodEnum.vnpay_qr]: [PaymentMethodEnum.vnpay_ewallet],
    [PaymentMethodEnum.vnpay_token]: [PaymentMethodEnum.vnpay_ewallet],
    [PaymentMethodEnum.vnpay_token_create]: [PaymentMethodEnum.vnpay_ewallet],
    [PaymentMethodEnum.vnpay_token_pay]: [PaymentMethodEnum.vnpay_ewallet],
    [PaymentMethodEnum.vnpay_token_pay_and_create]: [
        PaymentMethodEnum.vnpay_ewallet,
    ],
    [PaymentMethodEnum.vnpay_qr_offline]: [PaymentMethodEnum.vnpay_qr_offline],
    [PaymentMethodEnum.vnpt_dcpay]: [PaymentMethodEnum.vnpt_dcpay],
    [PaymentMethodEnum.alliex_pay]: [PaymentMethodEnum.alliex_pay],
    [PaymentMethodEnum.cnf_bnpl]: [PaymentMethodEnum.cnf_bnpl],
    [PaymentMethodEnum.vnpt_megapay]: [PaymentMethodEnum.vnpt_megapay],
    [PaymentMethodEnum.infoplus_dcpay]: [PaymentMethodEnum.infoplus_dcpay],
};
export const getTagStatus = {
    [PaymentStatus.Processing]: "yellow",
    [PaymentStatus.Success]: "green",
    [PaymentStatus.Refunded]: "orange",
    [PaymentStatus.Failed]: "red",
    [PaymentStatus.Canceled]: "grey",
};

export const TransformAlertConfig = (values: any): IAlertConfig => {
    const transformedConfig = {
        id: values.id || undefined,
        type: values.type?.length ? values.type : ["EMAIL"],
        name: values.name || "",
        category: values.category || "CRITICAL",
        httpStatusCodes: values.httpStatusCodes || "",
        services: values.services || [],
        emailsReceivers: values.emailsReceivers || [],
        emailHeader: values.emailHeader || "",
        emailSender: values.emailSender || "",
        webhooks: values.webhooks || [],
        conditions: values.conditions || "",
        phoneSender: values.phoneSender || null,
        duration: Number(values.duration) || 15,
        phonesReceivers: values.phonesReceivers || [],
        schedule: values.schedule || "",
        isActive: values.isActive,
        trigger: Number(values.trigger) || 0,
    };
    if (typeof transformedConfig.httpStatusCodes === "string") {
        transformedConfig.httpStatusCodes =
            TransFormFromStringToArrayByCommaSymbol(
                transformedConfig.httpStatusCodes
            ).map(Number);
    }
    if (typeof transformedConfig.emailsReceivers === "string") {
        transformedConfig.emailsReceivers =
            TransFormFromStringToArrayByCommaSymbol(
                transformedConfig.emailsReceivers
            );
    }
    if (typeof transformedConfig.webhooks === "string") {
        transformedConfig.webhooks = TransFormFromStringToArrayByCommaSymbol(
            transformedConfig.webhooks
        );
    }
    if (typeof transformedConfig.phonesReceivers === "string") {
        transformedConfig.phonesReceivers =
            TransFormFromStringToArrayByCommaSymbol(
                transformedConfig.phonesReceivers
            );
    }
    if (typeof transformedConfig.services === "string") {
        transformedConfig.services = TransFormFromStringToArrayByCommaSymbol(
            transformedConfig.services
        );
    }
    return transformedConfig;
};
